import Button from 'components/elements/Button/Button'
import { useFormContext, useWatch } from 'react-hook-form'
import css from './GetStarted.module.scss'

const GetStarted = () => {
	const { control } = useFormContext()
	const title = useWatch({
		control,
		name: 'titleSection2'
	})
	const subtitle = useWatch({
		control,
		name: 'subtitleSection2'
	})

	return (
		<div className="container-preview">
			<div className={css.wrapper}>
				<div className={css.text}>
					<h2 className={css.text__title}>{title}</h2>
					<p className={css.text__subtitle}>{subtitle}</p>
				</div>
				<div className={css.extra_wrapper}>
					<div className={css.extra}>
						<div className={css.extra__info}>
							<div className={css.extra__paid}>
								<span className={css.extra__subtext}>annual licence</span>
								<div className={css.extra__prepaid}>Pre-paid</div>
							</div>
							<div className={css.block}>
								<span className={css.price}>0</span>
								<span className={css.quantity_more}>children added</span>
							</div>
						</div>
						<div className={css.fieldWrapper}>
							<div className={css.label}>Licence key</div>
							<input
								type="text"
								className={css.field}
								disabled
								placeholder="Type licence key"
							/>
						</div>
						<Button variant="outlined" block classname={css.button}>
							Add more <i className="icon-big-plus" />
						</Button>
					</div>
					<div className={css.extra}>
						<div className={css.extra__info}>
							<div className={css.extra__paid}>
								<span className={css.extra__subtext}>monthly</span>
								<div className={css.extra__prepaid}>Licence</div>
							</div>
							<div className={css.block}>
								<sup className={css.currency}>$</sup>
								<span className={css.price}>4.80</span>
								<span className={css.quantity}>per child</span>
							</div>
						</div>
						<div className={css.extra__counter}>
							<div className={css.extra__trigger}>
								Please choose how many children you want to protect:
							</div>
							<div className={css.counter}>
								<button
									disabled
									type="button"
									className="icon-minus"
									aria-label="Minus"
								/>
								<div className={css.counter__count}>0</div>
								<button type="button" className="icon-plus" aria-label="Plus" />
							</div>
						</div>
					</div>
					<Button variant="primary" block classname={css.button}>
						Get started
					</Button>
				</div>
			</div>
		</div>
	)
}

export default GetStarted
